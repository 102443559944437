<template>
  <b-overlay :show="loading">
    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
      <b-form @submit.prevent="handleSubmit(createData)" @reset.prevent="reset">
        <b-row>
          <b-col sm="6">
            <ValidationProvider name="Mobile" vid="mobile" rules="required">
              <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="mobile"
                  slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{$t('globalTrans.mobile')}} <span class="text-danger">*</span>
                </template>
                <b-form-input
                    readonly
                    id="mobile"
                    v-model="formData.mobile"
                    :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
                <div class="display-block text-danger">
                  {{ info }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="6">
            <ValidationProvider name="Name" vid="name" rules="">
              <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="name"
                  slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{$t('globalTrans.name')}}
                </template>
                <b-form-input
                    readonly
                    id="name"
                    v-model="formData.name"
                    :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="6">
            <ValidationProvider name="Date" vid="date" rules="required">
              <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="date"
                  slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{$t('globalTrans.date')}}
                </template>
                <b-form-input
                    class="fromDate"
                    v-model="formData.date"
                    :placeholder="$t('globalTrans.select_date')"
                    :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="6">
            <ValidationProvider name="Course Document Name"  vid="course_docoment_setup_id" rules="required|min_value:1">
              <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="course_docoment_setup_id"
                  slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{$t('externalTraining.course_docoment_name')}}
                </template>
                <b-form-select
                  plain
                  v-model="formData.course_docoment_setup_id"
                  :options="courseDocumentSetupList"
                  id="course_docoment_setup_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="6">
            <ValidationProvider name="Reason (En)"  vid="reason" rules="">
              <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="reason"
                  slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{$t('elearning_iabm.reason')}}
                </template>
                <b-form-textarea
                    rows="2"
                    id="reason"
                    v-model="formData.reason"
                    :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-textarea>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="6">
            <ValidationProvider name="Reason (Bn)"  vid="reason_bn" rules="">
              <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="reason_bn"
                  slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{$t('elearning_iabm.reason_bn')}}
                </template>
                <b-form-textarea
                    rows="2"
                    id="reason_bn"
                    v-model="formData.reason_bn"
                    :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-textarea>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <!-- <ValidationProvider name="Course Documents" vid="course_document" rules="">
          <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="course_document"
              slot-scope="{ valid, errors }"
          >
            <template v-slot:label>
              {{ $t('elearning_iabm.course_document') }}<span class="text-danger">*</span>
            </template>
            <b-form-file
                id="course_document"
                @change="onChangeFile"
                :state="errors[0] ? false : (valid ? true : null)"
            ></b-form-file>
            <div class="invalid-feedback">
              {{ errors[0] }}
            </div>
          </b-form-group>
        </ValidationProvider> -->
        <div class="text-right">
          <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
          <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
        </div>
      </b-form>
    </ValidationObserver>
  </b-overlay>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { requestDocumentStore, requestDocumentUpdate } from '../../api/routes'
import flatpickr from 'flatpickr'
import { mapGetters } from 'vuex'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
    } else {
      this.formData.name = this.authUser.name
      if (this.authUser.is_admin) {
        this.formData.mobile = this.authUser.phone
      } else {
        this.formData.mobile = this.authUser.mobile
      }
    }
  },
  mounted () {
    flatpickr('.fromDate', {})
  },
  computed: {
    ...mapGetters({
    authUser: 'Auth/authUser'
    }),
    courseDocumentSetupList: function () {
      return this.$store.state.ExternalUserService.trainingPanel.commonObj.courseDocumentSetupList.filter(item => item.status === 1)
    }
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        course_docoment_setup_id: 0,
        mobile: '',
        name: '',
        date: '',
        reason: '',
        reason_bn: '',
        status: 1
      },
      loading: false,
      info: ''
    }
  },
  methods: {
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    // onChangeFile (e) {
    //   this.formData.course_document = e.target.files[0]
    // },
    async createData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      // var formData = new FormData()
      // Object.keys(this.formData).map(key => {
      //   formData.append(key, this.formData[key])
      // })
      if (this.formData.id) {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, `${requestDocumentUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, requestDocumentStore, this.formData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    }
  }
}
</script>
