<template>
    <div class="inner-section">
        <card>
            <template v-slot:searchHeaderTitle>
                <h4 class="card-title">{{$t('elearning_iabm.course_document')}}</h4>
            </template>
            <template v-slot:searchBody>
                <b-row>
                    <!-- <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="mobile"
                      >
                        <template v-slot:label>
                          {{$t('globalTrans.mobile')}}
                        </template>
                        <b-form-input
                            id="mobile"
                            v-model="search.mobile"
                        ></b-form-input>
                      </b-form-group>
                    </b-col> -->
                    <b-col sm="5">
                      <b-form-group
                          class="row"
                          label-cols-sm="3"
                          label-for="date_from"
                      >
                        <template v-slot:label>
                          {{$t('elearning_iabm.date_from')}}
                        </template>
                        <b-form-input
                            class="fromDate"
                            v-model="search.date_from"
                            :placeholder="$t('globalTrans.select_date')"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col sm="5">
                      <b-form-group
                          class="row"
                          label-cols-sm="2"
                          label-for="date_to"
                      >
                        <template v-slot:label>
                          {{$t('elearning_iabm.date_to')}}
                        </template>
                        <b-form-input
                            class="fromDate"
                            v-model="search.date_to"
                            :placeholder="$t('globalTrans.select_date')"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col sm="2">
                        <b-button size="sm" variant="primary" @click="searchData">
                            <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                        </b-button>
                    </b-col>
                </b-row>
            </template>
        </card>
        <body-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{$t('elearning_iabm.course_document_list')}}</h4>
            </template>
            <template v-slot:headerAction1>

            </template>
            <template v-slot:headerAction>
                <a href="javascript:" class="btn-add" @click="resetId" v-b-modal.modal-4>
                    <i class="ri-add-fill"></i> {{ $t('globalTrans.add_new') }}
                </a>
            </template>
            <template v-slot:body>
                <b-overlay :show="loadingState">
                    <select-column-check :labelData="labelData" :search="search" :columns="columns" />
                    <b-row>
                        <b-col md="12" class="table-responsive">
                            <b-table head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                                <template v-slot:cell(index)="data">
                                {{ $n(data.index + pagination.slOffset) }}
                                </template>
                                <template v-slot:cell(type_name)="data">
                                <span class="capitalize">{{ data.item.type_name }}</span>
                                </template>
                                <template v-slot:cell(date)="data">
                                    <span class="capitalize">{{ data.item.date | dateFormat }}</span>
                                </template>
                                <template v-slot:cell(course_document)="data">
                                    <a  v-if="data.item.course_document" target = '_blank' :href="baseUrl + 'download-attachment?file=uploads/iabm-documents/' + data.item.course_document" title="Course Document" class="mr-3" download><i class="ri-download-cloud-fill"></i></a>
                                </template>
                                <!-- <template v-slot:cell(status)="data">
                                    <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                    <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                </template> -->
                                <template v-slot:cell(action)="data">
                                    <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                    <!-- <span v-if="data.item.status === 1">
                                        <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="fas fa-window-close m-0"></i></b-button>
                                    </span>
                                    <span v-if="data.item.status === 2">
                                        <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="fa fa-check m-0"></i></b-button>
                                    </span> -->
                                </template>
                            </b-table>
                            <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                                <b-pagination
                                    v-model="pagination.currentPage"
                                    :perPage="search.limit"
                                    :total-rows="pagination.totalRows"
                                    @input="searchData"
                                />
                            </div>
                        </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </body-card>
        <b-modal id="modal-4" size="lg" hide-footer :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>

    </div>
</template>
<script>
import Form from './Form'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { requestDocumentList, requestDocumentToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import flatpickr from 'flatpickr'
import { mapGetters } from 'vuex'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form
    },
    data () {
        return {
            search: {
                mobile: '',
                date_from: '',
                date_to: '',
                limit: 20
            },
            baseUrl: trainingElearningServiceBaseUrl,
            labelData: [
                { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '10%' } },
                { labels: 'globalTrans.mobile', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '15%' } },
                { labels: 'globalTrans.name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '15%' } },
                { labels: 'globalTrans.date', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '15%' } },
                { labels: 'elearning_iabm.course_document', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '15%' } },
                { labels: 'globalTrans.reason', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 8, thStyle: { width: '20%' } },
                { labels: 'globalTrans.action', class: 'text-left', show: '1', order: 9, thStyle: { width: '10%' } }
            ]
        }
    },
    computed: {
         ...mapGetters({
        authUser: 'Auth/authUser'
        }),
        formTitle () {
            return (this.editItemId === 0) ? this.$t('elearning_iabm.request_coursee_document') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_iabm.request_coursee_document') + ' ' + this.$t('globalTrans.update')
        },
        columns () {
            const labelData = this.labelData
            const labels = labelData.map((item, index) => {
                return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
            })

            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'mobile' },
                { key: 'name' },
                { key: 'date' },
                { key: 'course_document' },
                { key: 'reason_bn' },
                // { key: 'status' },
                { key: 'action' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'mobile' },
                { key: 'name' },
                { key: 'date' },
                { key: 'course_document' },
                { key: 'reason' },
                // { key: 'status' },
                { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        this.loadData()
    },
    watch: {
        'search.limit': function (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.loadData()
        }
        }
    },
    mounted () {
        flatpickr('.fromDate', {})
    },
    methods: {
        dataChange () {
            this.loadData()
        },
        async searchData () {
            this.loadData()
        },
        remove (item) {
            this.changeStatus(trainingElearningServiceBaseUrl, requestDocumentToggleStatus, item, 'training_e_learning', 'trainerEvaluationList')
        },
        loadData () {
            // this.search.mobile = this.authUser.mobile_no
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(trainingElearningServiceBaseUrl, requestDocumentList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', response.data.data)
                    this.paginationData(response.data, this.search.limit)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        }
    }
}
</script>
